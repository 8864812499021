import { FacebookPost } from "../../utils/interfaces";

export const conversations: FacebookPost[] = [
  {
    id: "1",
    topic: "Israel's use of pager bombs in military operations",
    message:
      "From a law of war standpoint, Israel's pager bombs represent one of the most precisely targeted strikes in the history of warfare. I can't think of a single widespread strike on an armed force that's embedded in a civilian population that's been more precise. It's remarkable.",
    url: "https://x.com/DavidAFrench/status/1836176001019060332",
    commentsCount: 5,
    comments: [
      {
        id: "1",
        author: {
          name: "Dr. Amira Dalton",
          id: "libertymama86",
        },
        content:
          "Remarkable and from a Law of war standpoint , Israel just committed a war crime . Imagine his response if a militant group had put explosives in the phones of US servicemen and their children were killed",
        likes: 1100,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "staswilf",
              id: "staswilf",
            },
            content:
              'No, Israel didn\'t. Please go and educate yourself on laws of war and the term "collateral damage".',
            likes: 8,
            sentiment: "positive",
            comments: [
              {
                id: "3",
                author: {
                  name: "linkshund",
                  id: "linkshund",
                },
                content: "Collateral damage is murder.",
                likes: 6,
                sentiment: "negative",
                comments: [],
              },
            ],
          },
        ],
      },
      {
        id: "5",
        author: {
          name: "Jamal Dajani جمال",
          id: "JamalDajani",
        },
        content:
          "You are one sick individual. Children have been killed and shoppers, medics and ordinary people have been maimed. This is a terrorist attack that will only invite retaliation that will cause the death of innocent civilians.",
        likes: 452,
        sentiment: "negative",
        comments: [
          {
            id: "6",
            author: {
              name: "Miffy in New York",
              id: "dklompsma",
            },
            content:
              "Maybe tell your terrorist friends not to try to murder Jews for -let's say- a decade. You'd be surprised with the results.",
            likes: 236,
            sentiment: "positive",
            comments: [],
          },
          {
            id: "9",
            author: {
              name: "Julia",
              id: "theophimia",
            },
            content:
              "This is an amazingly surgical military operation. Yes, Israel has the right to defend itself. But we know that the Jihadi lovers will never be happy...",
            likes: 0,
            sentiment: "positive",
            comments: [],
          },
        ],
      },
      {
        id: "10",
        author: {
          name: "Rob Howse",
          id: "howserob",
        },
        content:
          "If you simply accept the line that all of the pagers targeted were personally used by combatants, then there is something to that. But proof of that? What I can learn so far, from public sources, is they were merely purchased by Hezbollah, both terror group & political party.",
        likes: 47,
        sentiment: "negative",
        comments: [
          {
            id: "13",
            author: {
              name: "Zarah Sultana MP",
              id: "zarahsultana",
            },
            content:
              "Still waiting for your condemnation of #Hezbollah's year long bombardment of northern Israel which forced 60,000 from their homes and killed Druze children, Zarah? Cat got your tongue?",
            likes: 1700,
            sentiment: "positive",
            comments: [
              {
                id: "15",
                author: {
                  name: "Dennis Belogorsky",
                  id: "DBelogorsky",
                },
                content:
                  'Because she only cares about the "rules of warfare" when it applies to Israel and not to the paramilitary terrorist groups that she tacitly supports.',
                likes: 13,
                sentiment: "positive",
                comments: [
                  {
                    id: "16",
                    author: {
                      name: "|",
                      id: "wtfjunalisa",
                    },
                    content:
                      "We all get proper information this side of the world. Sorry that doesn't match what American media owned by Zionists pushes out or your AIPAC owned politicians.",
                    likes: 0,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    topic: "Double Standard in Politics in relation to Israel and Palestine",
    message: "",
    url: "https://x.com/AOC/status/1837220813176721773",
    commentsCount: 5,
    comments: [
      {
        id: "1",
        author: {
          name: "Aviva Klompas",
          id: "AvivaKlompas",
        },
        content:
          "Can you remind us when you last called out Hezbollah, a US/designated terrorist organization?",
        likes: 2200,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "SDL",
              id: "SocDoneLeft",
            },
            content:
              "The ICC ruled Israel's conduct in the war is criminal. When have you called for the US to extradite Netanyahu and Ben-Gvir to the Hague? When have you called for an independent investigation into Israeli soldiers killing American protesters? You don't care. Stop pretending",
            likes: 1300,
            sentiment: "negative",
            comments: [],
          },
          {
            id: "3",
            author: {
              name: "Walter is a Bulldog",
              id: "Walter58930677",
            },
            content:
              'Here\'s what would happen if Hamas and hezbollah and all the other terrorist orgs in the region called for an end to all actions against Israel… if they put down their guns and said "no more war." There would be peace. If Israel did that, they would be wiped off the map.',
            likes: 6,
            sentiment: "negative",
            comments: [
              {
                id: "4",
                author: {
                  name: "simpler times",
                  id: "timessimpler7",
                },
                content:
                  "I honestly just don't believe you're this brain dead. I don't believe it.",
                likes: 13,
                sentiment: "negative",
                comments: [],
              },
            ],
          },
          {
            id: "5",
            author: {
              name: "Jewish Starlight",
              id: "jewishstarlight",
            },
            content:
              "Call out Hezbollah? That would be Islamophobic and anti-Arab!!!",
            likes: 779,
            sentiment: "negative",
            comments: [
              {
                id: "6",
                author: {
                  name: "Zach Pryor",
                  id: "MagicEater96",
                },
                content: "Okay, Zionist. You're a disgrace to Judaism.",
                likes: 2,
                sentiment: "negative",
                comments: [
                  {
                    id: "7",
                    author: {
                      name: "The Bear Jew's Bat",
                      id: "ornerydonowitz",
                    },
                    content:
                      "Maybe stop haunting the whole thread and make your life slightly less of an abject failure. Spoiler: you won't.",
                    likes: 0,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "8",
        author: {
          name: "AmericanPapaBear",
          id: "AmericaPapaBear",
        },
        content:
          "You guys have no room to talk. You are pro hamas sympathizers. Sit down.",
        likes: 809,
        sentiment: "negative",
        comments: [
          {
            id: "9",
            author: {
              name: "mikemakescartoons",
              id: "mikemakestoons",
            },
            content:
              "We can't reduce this conversation to assuming people are only for Hamas or the Israeli government. The death of civilians gets trivialized when we do. Too many people are pointing fingers in the latest iteration of a 3000 year old conflict. Advocating for less racism is good.",
            likes: 493,
            sentiment: "neutral",
            comments: [
              {
                id: "10",
                author: {
                  name: "Moopsy",
                  id: "Chef_EZToon",
                },
                content:
                  "the deaths of civilians dont get trivialized as an israel supporter, it does as a hamas supporter, because thats hama's goal, get as many people killed as possible.",
                likes: 79,
                sentiment: "negative",
                comments: [
                  {
                    id: "11",
                    author: {
                      name: "Kastor",
                      id: "kastorcaster",
                    },
                    content:
                      "Yeah! Hamas actually just rigged explosives in an entire shipment of pagers because they knew a handful of them would go to military targets, killed 10+ innocents, including children and severely injured almost 3000 people.",
                    likes: 0,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1",
    topic:
      "Islamophobia, anti-Arab sentiment, and international relations in the Middle East",
    message:
      "Shame on the @detroitnews for allowing this racist, xenophobic vile cartoon on their platform. Pay attention to who condemns this. And then recognize the different standard Arab and Muslim politicians are held by.",
    url: "",
    commentsCount: 8,
    comments: [
      {
        id: "1",
        author: {
          name: "Abraham Aiyash",
          id: "AbrahamAiyash",
        },
        content:
          'When I was doxxed by the far right and had threats made against me and my family, party leaders told me I "shouldn\'t have said" things to "stoke anger" (I was calling out the racist attempt to not certify the election in Detroit). This normalization of hate will induce violence',
        likes: 1700,
        sentiment: "negative",
        comments: [
          // ... (previous comments remain the same)
        ],
      },
      {
        id: "13",
        author: {
          name: "Bailey",
          id: "realsnoopbailey",
        },
        content:
          "You're right, Abraham. Congresspersons should be able to back whatever islamic terrorist groups they choose to without any criticism whatsoever. Especially the ones that want to wipe out that tiny little evil Jewish country.",
        likes: 535,
        sentiment: "negative",
        comments: [
          // ... (previous comments remain the same)
        ],
      },
      {
        id: "18",
        author: {
          name: "Expat Vibes",
          id: "expatvibes",
        },
        content:
          "The U.S. State Dept says the U.S. was not involved in the pager explosions in Lebanon and was not aware of the incident beforehand. In unrelated news, the American University of Beirut Medical Center replaced the pagers of their doctors and staff 2 weeks ago.",
        likes: 53000,
        sentiment: "neutral",
        comments: [
          {
            id: "19",
            author: {
              name: "Cryptiano Omar",
              id: "CryptianoOmarYT",
            },
            content:
              "Man, they always lie. They do all the dirty work for Israel and act like they're never even involved. CIA is like the boss of Mossad. They work hand in hand",
            likes: 501,
            sentiment: "negative",
            comments: [
              {
                id: "20",
                author: {
                  name: "Elizabeth Grossman",
                  id: "ejglaw",
                },
                content:
                  "Do think that international cooperation is a bad thing?",
                likes: 0,
                sentiment: "neutral",
                comments: [
                  {
                    id: "21",
                    author: {
                      name: "Cyberworld 9000",
                      id: "9000Cyberworld",
                    },
                    content: "When they're doing terror attacks????? Yes.",
                    likes: 129,
                    sentiment: "negative",
                    comments: [],
                  },
                  {
                    id: "22",
                    author: {
                      name: "محمد خطاب",
                      id: "mhmdkht93685944",
                    },
                    content:
                      "You mean terrorist cooperation, not international. Please put the words in their correct context",
                    likes: 34,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    topic:
      "US involvement in Lebanon pager explosions and international cooperation",
    message:
      "The U.S. State Dept says the U.S. was not involved in the pager explosions in Lebanon and was not aware of the incident beforehand. In unrelated news, the American University of Beirut Medical Center replaced the pagers of their doctors and staff 2 weeks ago.",
    url: "https://x.com/expatvibes/status/1836118467600437632",
    commentsCount: 3,
    comments: [
      {
        id: "1",
        author: {
          name: "Cryptiano Omar",
          id: "CryptianoOmarYT",
        },
        content:
          "Man, they always lie. They do all the dirty work for Israel and act like they're never even involved. CIA is like the boss of Mossad. They work hand in hand",
        likes: 501,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "Elizabeth Grossman",
              id: "ejglaw",
            },
            content: "Do think that international cooperation is a bad thing?",
            likes: 0,
            sentiment: "positive",
            comments: [
              {
                id: "3",
                author: {
                  name: "Cyberworld 9000",
                  id: "9000Cyberworld",
                },
                content: "When they're doing terror attacks????? Yes.",
                likes: 129,
                sentiment: "negative",
                comments: [],
              },
              {
                id: "4",
                author: {
                  name: "محمد خطاب",
                  id: "mhmdkht93685944",
                },
                content:
                  "You mean terrorist cooperation, not international. Please put the words in their correct context",
                likes: 34,
                sentiment: "negative",
                comments: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
