import { AWS_SERVER_URL } from "../CampaignConst";
import { LLMGenerateRequest } from "../CampaignInterface";
import { camelToSnake, snakeToCamel } from "./UsersService";

interface LLMGenerateResponse {
  content: string;
  responseMetadata: Record<string, any>;
}
export async function generateResponse(
  request: LLMGenerateRequest
): Promise<LLMGenerateResponse> {
  const lambdaUrl =
    "https://fimnw3ne3y2v7n2b2cmxvdgeve0amsvh.lambda-url.us-east-1.on.aws/";
  const snakeCaseRequest = camelToSnake(request);
  console.log(snakeCaseRequest);
  const response = await fetch(lambdaUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": request.apiKey || "",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(snakeCaseRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Error generating response: ${response.statusText}, ${response}`
    );
  }

  return snakeToCamel(response.json());
}

const isLocal = false;
const API_BASE_URL = isLocal
  ? "http://localhost:8000"
  : "https://flask-whatsapp-server.blackdune-10302979.eastus.azurecontainerapps.io";

interface ResponseParameters {
  response_length: string;
  communication_tone: "logical" | "sarcastic" | "aggressive";
  language_style: "slang" | "casual" | "formal";
}

interface State {
  topic: string;
  stance: string;
  conversation: string[];
}

interface TreeOfThoughtRequest {
  reasoning_type: string;
  use_chain_of_thought: boolean;
  node_selection_strategy: "greedy" | "sample";
  evaluation_strategy: "score" | "vote";
  response_strategy:
    | "self_reflection"
    | "tree_search"
    | "generate_from_reasoning";
  do_pruning: boolean;
  top_k: number;
  n_samples_judge: number;
  n_samples_generation: number;
  judge_temperature: number;
  generation_temperature: number;
  rollout_depth: number;
  monte_carlo_iterations: number;
  conversation_state: State;
  response_parameters: ResponseParameters;
  with_visualization: boolean;
  save_tree: boolean;
  search_strategy: "monte_carlo" | "tree_of_thoughts";
}

export async function pollTaskResult(
  taskId: string,
  apiKey: string
): Promise<string> {
  const maxAttempts = 180;
  const delayMs = 1000;
  try {
    console.log("Polling task result for task ID:", taskId);
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const response = await fetch(`${API_BASE_URL}/task/${taskId}`, {
        headers: {
          "X-Api-Key": apiKey,
        },
      });

      if (!response.ok) {
        console.log("Error polling task result:", response);
        return `Error polling task result: ${response}`;
      }

      const result = await response.json();
      console.log("Result:", result);
      if (result.status !== "processing") {
        if (typeof result === "string") {
          return result;
        } else {
          if (JSON.stringify(result).toLowerCase().includes("error")) {
            return "error generating response";
          } else {
            return JSON.stringify(result).replace(/\\n/g, "\n");
          }
        }
      }

      await new Promise((resolve) => setTimeout(resolve, delayMs));
    }

    return "Task processing timed out after 180 seconds";
  } catch (error) {
    console.error("Error polling task result:", error);
    return `Error polling task result: ${error}`;
  }
}

// export async function generateResponseWithEndpoint(
//   request: LLMGenerateRequest,
//   useMonteCarloTreeOfThought: boolean = true
// ): Promise<string> {
//   const endpointUrl = useMonteCarloTreeOfThought
//     ? `${API_BASE_URL}/monte_carlo_tree_of_thought`
//     : `${API_BASE_URL}/tree_of_thought`;

//   const treeOfThoughtRequest: TreeOfThoughtRequest = {
//     topic: request.provided_thread?.threadId || "",
//     stance: request.stance || "ANTI",
//     conversation: request.provided_thread?.messages.map((m) => m.content) || [],
//     monte_carlo_iterations: 13,
//     rollout_depth: 3,
//     generation_temperature: 0.7,
//     judge_temperature: 0.7,
//     n_samples_generation: 3,
//     n_samples_judge: 5,
//     do_visualize: false,
//     do_save_tree: false,
//     response_length: mapResponseLength(request.responseParameters.length),
//     communication_tone: mapResponseTone(request.responseParameters.tone),
//     language_style: mapResponseFormality(request.responseParameters.formality),
//     use_chain_of_thought: true,
//   };
//   console.log(treeOfThoughtRequest);

//   const response = await fetch(endpointUrl, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "X-Api-Key": request.apiKey || "",
//     },
//     body: JSON.stringify(treeOfThoughtRequest),
//   });

//   if (!response.ok) {
//     throw new Error(`Error generating response: ${response.statusText}`);
//   }

//   const result = await response.json();
//   const taskId = result.task_id;

//   return pollTaskResult(taskId, request.apiKey || "");
// }

function mapResponseLength(length?: string): string {
  switch (length) {
    case "one sentence":
      return "One sentence";
    case "two sentences":
      return "Two sentences";
    case "a few sentences":
      return "A few sentences";
    case "one paragraph":
      return "One paragraph";
    case "a few paragraphs":
      return "A few paragraphs";
    default:
      return "A few sentences";
  }
}

function mapResponseTone(tone?: string): string {
  switch (tone) {
    case "logical":
      return "logical";
    case "aggressive":
      return "aggressive";
    case "sarcastic":
      return "sarcastic";
    case "empathetic":
      return "empathetic";
    case "dramatic":
      return "dramatic";
    default:
      return "logical";
  }
}

function mapResponseFormality(formality?: string): string {
  switch (formality) {
    case "formal":
      return "formal";
    case "casual":
      return "casual";
    case "slang":
      return "slang";
    default:
      return "casual";
  }
}

interface ResponseParameters {
  response_length: string;
  communication_tone: "logical" | "sarcastic" | "aggressive";
  language_style: "slang" | "casual" | "formal";
}

interface State {
  topic: string;
  stance: string;
  conversation: string[];
}

interface TreeOfThoughtRequest {
  reasoning_type: string;
  use_chain_of_thought: boolean;
  node_selection_strategy: "greedy" | "sample";
  evaluation_strategy: "score" | "vote";
  response_strategy:
    | "self_reflection"
    | "tree_search"
    | "generate_from_reasoning";
  do_pruning: boolean;
  top_k: number;
  n_samples_judge: number;
  n_samples_generation: number;
  judge_temperature: number;
  generation_temperature: number;
  rollout_depth: number;
  monte_carlo_iterations: number;
  conversation_state: State;
  response_parameters: ResponseParameters;
  with_visualization: boolean;
  save_tree: boolean;
  search_strategy: "monte_carlo" | "tree_of_thoughts";
}

export async function generateLatestTree(
  request: LLMGenerateRequest,
  useMonteCarloTreeOfThought: boolean = true
): Promise<string> {
  const endpointUrl = `${API_BASE_URL}/tree_of_thoughts`;
  const treeOfThoughtRequest: TreeOfThoughtRequest = {
    reasoning_type: request.reasoningType || "devils_advocate",
    use_chain_of_thought: true,
    node_selection_strategy: "greedy",
    evaluation_strategy: "score",
    response_strategy: "tree_search",
    do_pruning: true,
    top_k: 2,
    n_samples_judge: 5,
    n_samples_generation: 3,
    judge_temperature: 0.5,
    generation_temperature: 1.0,
    rollout_depth: 2,
    monte_carlo_iterations: 10,
    conversation_state: {
      topic: request.provided_thread?.threadId || "",
      stance: request.stance || "ANTI",
      conversation:
        request.provided_thread?.messages.map((m) => m.content) || [],
    },
    response_parameters: {
      response_length: mapResponseLength(request.responseParameters.length),
      communication_tone: mapResponseTone(request.responseParameters.tone) as
        | "logical"
        | "sarcastic"
        | "aggressive",
      language_style: mapResponseFormality(
        request.responseParameters.formality
      ) as "slang" | "casual" | "formal",
    },
    with_visualization: false,
    save_tree: false,
    search_strategy: useMonteCarloTreeOfThought
      ? "monte_carlo"
      : "tree_of_thoughts",
  };

  console.log("Tree of Thought Request:", treeOfThoughtRequest);
  console.log("API Key:", request.apiKey);
  const response = await fetch(endpointUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": request.apiKey || "",
    },
    body: JSON.stringify(treeOfThoughtRequest),
  });

  if (!response.ok) {
    return `Error generating tree of thoughts: ${response.statusText}`;
  }

  const result = await response.json();
  const taskId = result.task_id;
  console.log("Task ID:", taskId);
  return pollTaskResult(taskId, request.apiKey || "");
}
