import { useEffect, useRef, useState } from "react";
import { Platforms } from "../../utils/interfaces";
import { Box, Card, Stack, Skeleton } from "@mui/material";
import { useScroll } from "react-spring";
import { moveEmbedId, showEmbedId } from "../../utils/constant";
import { recognizePlatform } from "../../utils/postUtils";
import {
  getUrlForEmbbeding,
  buildLoadScript,
} from "../../utils/PostEmbeddingHelperFunctions";
import React from "react";

declare global {
  interface Window {
    instgrm: {
      Embeds: {
        process: () => void;
      };
    };
  }
}

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

interface Prop {
  url: string;
  isAlone?: boolean; // used as prop for times this componenet is rendered alone
}

const PostEmbbdingComponent = ({ url, isAlone = false }: Prop) => {
  const uniqueIdRef = useRef(`post-embed-${moveEmbedId()}`); // Create a unique ID for each component instance
  const [Loading, setLoading] = useState(true);
  const socialPlatform = recognizePlatform(url);

  url = getUrlForEmbbeding(url);
  console.log(socialPlatform, url);
  useEffect(() => {
    const script = buildLoadScript(socialPlatform);
    document.body.appendChild(script);

    // Additional handling for Facebook SDK
    if (socialPlatform === Platforms.facebook) {
      if (window.FB) {
        // If FB SDK is already loaded, process the embed
        window.FB.XFBML.parse();
      } else {
        // Initialize FB SDK
        window.fbAsyncInit = function () {
          window.FB.init({
            xfbml: true,
            version: "v3.2", // specify your version here
          });
        };
      }
    }

    return () => {
      // Cleanup script if necessary
    };
  }, [socialPlatform]);

  useEffect(() => {
    const interval = setInterval((getUrlForEmbbeding) => {
      let iframe;
      const uniqueDiv =
        document.getElementById(uniqueIdRef.current) || document; // Target the specific div of this component instance

      switch (socialPlatform) {
        case Platforms.twitter:
          iframe = uniqueDiv.querySelector(".twitter-tweet");
          break;
        case Platforms.instagram:
          iframe = uniqueDiv.querySelector(".instagram-media-rendered");
          break;
        default:
          iframe = uniqueDiv.querySelector(".instagram-media-rendered");
          break;
      }
      if (iframe) {
        if (socialPlatform === Platforms.twitter) {
          if (isAlone) {
            setLoading(false);
            clearInterval(interval);
          } else {
            const t = setTimeout(() => {
              setLoading(false);
              clearInterval(interval);
            }, 2000);
          }
        } else {
          setLoading(false);
          clearInterval(interval);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const renderEmbed = () => {
    switch (socialPlatform) {
      case Platforms.twitter:
        return (
          <>
            <div>
              <blockquote className="twitter-tweet">
                <a
                  href={url.replace("https://x.com/", "https://twitter.com/")}
                ></a>
              </blockquote>
            </div>
          </>
        );
        break;
      case Platforms.instagram:
        return (
          <>
            <div>
              <blockquote
                data-instgrm-captioned
                className="instagram-media"
                data-instgrm-permalink={url}
                style={{ minWidth: "1px", width: "100%" }}
              ></blockquote>
            </div>
          </>
        );
        break;
      case Platforms.facebook:
        return (
          <Box overflow="hidden">
            <iframe
              src={`https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(
                url
              )}&show_text=true&width=500`}
              width="100%"
              height="400px"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              onLoad={handleIframeLoad}
            ></iframe>
          </Box>
        );
      default:
        return <div>Unsupported platform</div>;
    }
  };
  return (
    <>
      <div id={uniqueIdRef.current}>
        {renderEmbed()}
        {Loading ? (
          <Stack spacing={1}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", bgcolor: "grey.400" }}
            />
            <Skeleton
              sx={{ bgcolor: "grey.400" }}
              variant="circular"
              width={40}
              height={40}
            />
            <Skeleton
              sx={{ bgcolor: "grey.400" }}
              variant="rectangular"
              width={"100%"}
              height={120}
            />
            <Skeleton
              sx={{ bgcolor: "grey.400" }}
              variant="rounded"
              width={"100%"}
              height={120}
            />
          </Stack>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const PostEmbbding = React.memo(PostEmbbdingComponent);
// prevent rerender unless there is a change to the url
export default PostEmbbding;
