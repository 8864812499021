import { useEffect, useState } from "react";
import {
  FacebookPost,
  Platforms,
  slidersValue,
} from "../../../utils/interfaces";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { sendFacebookComment } from "../SocialMediaService/facebookActions";
import SliderControls from "./CampaignSliderControls";
import {
  campaignAnswerStyles,
  customConversationStyles,
} from "./CampaignStyles";
import { recognizePlatform } from "../../../utils/postUtils";
import { extractTwitterID } from "../../../utils/PostCardHelperFunctions";
import { Agent } from "../CampaignInterface";
import {
  convertSliderValue,
  facebookPostToThread,
} from "../EndpointService/CampaignUtils";
import { generateLatestTree } from "../EndpointService/ResponseService";
import { USER_INSTRUCTIONS } from "../CampaignConst";
import { useTheme } from "@mui/material/styles";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { alpha } from "@mui/material/styles";
import { extractConversationThread } from "../EndpointService/CampaignUtils";
import Divider from "@mui/material/Divider";
import { LOADING_MESSAGE } from "../CampaignConst";
import { timerStyles } from "./CampaignStyles";

interface AnswerLog {
  text: string;
  slidersValue: slidersValue;
}

interface prop {
  potentialAnswer: string | undefined;
  isPrepeared: boolean | undefined;
  campaignId: string;
  agent: Agent;
  commentId: string;
  isOpen: boolean;
  author: { name: string; id: string };
  content: string;
  post: FacebookPost;
  apiKey: string;
}

const CampaignAnswer = ({
  author,
  content,
  agent,
  campaignId,
  commentId,
  post,
  apiKey,
}: prop) => {
  const [isCommentSending, setIsCommentSending] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [userInstructionText, setUserInstructionText] = useState("");
  const [useMonteCarloTreeOfThought, setUseMonteCarloTreeOfThought] =
    useState(false);
  const [stance, setStance] = useState<"PRO" | "ANTI">("ANTI");
  const [strategy, setStrategy] = useState<"monte_carlo" | "tree_of_thought">(
    "monte_carlo"
  );
  const [reasoningType, setReasoningType] = useState<
    "devils_advocate" | "iterative_drafting" | "plan_and_execute"
  >("devils_advocate");

  const [sliderValue, setSliderValue] = useState<slidersValue>({
    toneValue: 2,
    lengthValue: 3,
    languageStyleValue: 2,
    communicationToneValue: 2,
  });
  const [previousResponses, setPreviousResponses] = useState<AnswerLog[]>([]);
  const styles = campaignAnswerStyles;
  const theme = useTheme();
  const [timer, setTimer] = useState(0);

  const getSentimentStyle = (
    sentiment?: "positive" | "neutral" | "negative"
  ) => {
    switch (sentiment) {
      case "positive":
        return {
          borderLeft: `3px solid ${theme.palette.success.main}`,
          background: `linear-gradient(to right, ${alpha(
            theme.palette.success.light,
            0.1
          )}, transparent)`,
        };
      case "negative":
        return {
          borderLeft: `3px solid ${theme.palette.error.main}`,
          background: `linear-gradient(to right, ${alpha(
            theme.palette.error.light,
            0.1
          )}, transparent)`,
        };
      default:
        return {
          borderLeft: `3px solid ${theme.palette.grey[400]}`,
          background: `linear-gradient(to right, ${alpha(
            theme.palette.grey[300],
            0.1
          )}, transparent)`,
        };
    }
  };

  const handleStanceChange = (
    event: React.MouseEvent<HTMLElement>,
    newStance: "PRO" | "ANTI"
  ) => {
    if (newStance !== null) {
      setStance(newStance);
    }
  };

  const handleStrategyChange = (
    event: React.MouseEvent<HTMLElement>,
    newStrategy: "monte_carlo" | "tree_of_thought"
  ) => {
    if (newStrategy !== null) {
      setStrategy(newStrategy);
    }
  };

  const handleReasoningTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newReasoningType:
      | "devils_advocate"
      | "iterative_drafting"
      | "plan_and_execute"
  ) => {
    if (newReasoningType !== null) {
      setReasoningType(newReasoningType);
    }
  };

  const handleRegenerate = async () => {
    console.log(userInstructionText);
    setIsLoading(true);
    setResponseText("Loading ...");
    setTimer(0);
    const startTime = Date.now();
    const timerInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      setTimer(Math.floor(elapsedTime / 10) / 100);
    }, 10);

    try {
      const conversationThread = extractConversationThread(
        post.comments,
        commentId
      );
      const result = await generateLatestTree(
        {
          userId: agent.agentCreatorId,
          campaignId: campaignId,
          agentId: agent.agentId,
          threadId: "no",
          provided_thread: {
            threadId: post.topic || "",
            messages: [
              {
                role: "user",
                name: author.name,
                content: post.message,
              },
              ...conversationThread.map((comment) => ({
                role: "user",
                name: comment.author.name,
                content: comment.content,
              })),
            ],
          },
          responseParameters: convertSliderValue(
            sliderValue,
            userInstructionText ? userInstructionText : USER_INSTRUCTIONS
          ),
          targetId: commentId,
          apiKey: apiKey,
          stance: stance,
          reasoningType: reasoningType,
        },
        useMonteCarloTreeOfThought
      );
      setResponseText(result);
      setPreviousResponses([
        ...previousResponses,
        {
          text: result,
          slidersValue: { ...sliderValue },
        },
      ]);
    } catch (error: any) {
      console.error(error);
      setResponseText(error.message || "An error occurred");
    } finally {
      setIsLoading(false);
      clearInterval(timerInterval);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleHelpPostClick = () => {
    copyToClipboard();
    setTimeout(() => {
      handlePostSent(post.url, recognizePlatform(post.url));
    }, 100);
  };

  const handlePostSent = (url: string, platform: string) => {
    console.log(url);
    console.log(platform);

    if (platform == Platforms.instagram || platform == Platforms.facebook)
      window.open(url, "_blank");
    if (platform == Platforms.twitter) {
      url =
        "https://twitter.com/intent/tweet?in_reply_to=" +
        extractTwitterID(url) +
        "&text=" +
        responseText;
      window.open(url, "_blank");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(responseText)
      .then(() => {})
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  useEffect(() => {
    console.log(sliderValue);
  }, [sliderValue]);
  return (
    <Card sx={styles.card}>
      <CardContent>
        <Stack spacing={2}>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <ChatBubbleOutlineIcon fontSize="small" />
            Respond to Conversation
          </Typography>
          <Box
            sx={{
              ...styles.conversationContext,
              ...getSentimentStyle(
                post.comments.find((comment) => comment.id === commentId)
                  ?.sentiment
              ),
              p: 2,
              borderRadius: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
              Responding to:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {author.name}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {content}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="caption" color="text.secondary">
                {
                  post.comments.find((comment) => comment.id === commentId)
                    ?.likes
                }{" "}
                likes
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {
                  post.comments.find((comment) => comment.id === commentId)
                    ?.comments?.length
                }{" "}
                replies
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.textFieldContainer}>
            <TextField
              sx={{
                ...(isLoading && styles.loadingAnimation),
              }}
              inputProps={{ style: { fontSize: 12 } }}
              label="Use the sliders box to adjust the tone of your response"
              multiline
              minRows={5}
              value={isLoading ? LOADING_MESSAGE : responseText}
              onChange={(e: any) => setResponseText(e.target.value)}
              disabled={isLoading}
            />
            {isLoading && (
              <Box sx={customConversationStyles.loadingContainer}>
                <CircularProgress size={24} />
                <Box sx={timerStyles.timerContainer}>
                  <Typography sx={timerStyles.timerText}>{timer}s</Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={styles.contentContainer}>
            <Box sx={styles.sliderControlsContainer}>
              <Box sx={styles.buttonContainer}>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                    <Box>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Stance:
                      </Typography>
                      <ToggleButtonGroup
                        value={stance}
                        exclusive
                        onChange={handleStanceChange}
                        aria-label="stance"
                        size="small"
                        sx={{
                          "& .MuiToggleButton-root": {
                            "&.Mui-selected": {
                              backgroundColor: "primary.light",
                              color: "primary.contrastText",

                              "&:hover": {
                                backgroundColor: "primary.200",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "primary.50",
                            },
                          },
                        }}
                      >
                        <ToggleButton value="PRO" aria-label="pro stance">
                          PRO
                        </ToggleButton>
                        <ToggleButton value="ANTI" aria-label="anti stance">
                          ANTI
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Strategy:
                      </Typography>
                      <ToggleButtonGroup
                        value={strategy}
                        exclusive
                        onChange={handleStrategyChange}
                        aria-label="strategy"
                        size="small"
                        sx={{
                          "& .MuiToggleButton-root": {
                            "&.Mui-selected": {
                              backgroundColor: "primary.light",
                              color: "primary.contrastText",

                              "&:hover": {
                                backgroundColor: "primary.200",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "primary.50",
                            },
                          },
                        }}
                      >
                        <ToggleButton
                          value="monte_carlo"
                          aria-label="monte carlo strategy"
                        >
                          Monte Carlo
                        </ToggleButton>
                        <ToggleButton
                          value="tree_of_thought"
                          aria-label="tree of thought strategy"
                        >
                          Tree of Thought
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Reasoning Type:
                      </Typography>
                      <ToggleButtonGroup
                        value={reasoningType}
                        exclusive
                        onChange={handleReasoningTypeChange}
                        aria-label="reasoning type"
                        size="small"
                        sx={{
                          "& .MuiToggleButton-root": {
                            "&.Mui-selected": {
                              backgroundColor: "primary.light",
                              color: "primary.contrastText",

                              "&:hover": {
                                backgroundColor: "primary.200",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "primary.50",
                            },
                          },
                        }}
                      >
                        <ToggleButton
                          value="devils_advocate"
                          aria-label="devil's advocate reasoning"
                        >
                          Devil's Advocate
                        </ToggleButton>
                        <ToggleButton
                          value="iterative_drafting"
                          aria-label="iterative drafting reasoning"
                        >
                          Iterative Drafting
                        </ToggleButton>
                        <ToggleButton
                          value="plan_and_execute"
                          aria-label="plan and execute reasoning"
                        >
                          Plan & Execute
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider orientation="horizontal" sx={{ py: 1 }} />
              <SliderControls
                isLoading={isLoading}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
            </Box>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            color="secondary"
            size="small"
            variant="contained"
            sx={styles.generateButton}
            onClick={handleRegenerate}
            startIcon={<RefreshIcon />}
          >
            Generate Response
          </Button>
          <Button
            sx={styles.replyButton}
            variant="contained"
            size="small"
            onClick={handleHelpPostClick}
          >
            {isCommentSending ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              "Reply"
            )}
          </Button>
        </Stack>
      </CardContent>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Comment sent successfully!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CampaignAnswer;
